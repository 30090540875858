import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Team = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Equipo de Expertos en Drupal | asdloop" description="Somos un equipo de profesionales especializados en Drupal, dando servicios a empresas de todo el mundo."/>
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <div className="col">
          <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Expertos en Drupal</h1>
          <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Contamos con un equipo con todas las habilidades necesarias para desarrollar productos digitales con Drupal a partir de tu necesidad.</p>
        </div>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <span>
              <strong className="text-left">Innovación tecnológica</strong>
            </span>
            <br />dentro del ecosistema Drupal.
          </h2>
          <p className="lead pt-4 pb-5">Buscamos la innovación constante introduciendo las últimas tendencias tecnológicas que acompañan al ecosistema Drupal: Headless Drupal, ReactJS, GatsbyJS, etc. Solo cuando el proyecto lo requiere.</p>
          <h2>
            <span>
              <strong className="text-left">Cómodos con el trabajo en remoto</strong>
            </span>
            <br />pero nos gusta compaginar con presencial.
          </h2>
          <p className="lead pt-4 pb-5">Muchos de nuestros clientes están fuera de España, y no siempre coincidimos físicamente con nuestros colaboradores, por lo que hemos adoptado una filosofía de trabajo en remoto. Esto no es incompatible con que de vez en cuando nos guste juntarnos presencialmente con nuestros clientes más cercanos o compartir sesiones de trabajo presencial con todo el equipo.</p>
          <h2>
            <span>
              <strong className="text-left">Equipo especializado</strong>
            </span>
            <br />en productos digitales.
          </h2>
          <p className="lead pt-4 pb-5">Somos un equipo pequeño, pero con especialistas en cada una de las áreas de conocimiento necesarias para desarrollar productos digitales de cualquier tipo.</p>
          <h2>
            <span>
              <strong className="text-left">Nos gusta la transparencia</strong>
            </span>
            <br />por ambas partes.
          </h2>
          <p className="lead pt-4 pb-5">El tamaño de nuestra agencia nos permite seleccionar los proyectos con los que trabajamos. Nos gusta divertirnos con lo que hacemos, entregar valor a nuestros clientes y ser honestos en nuestro trabajo. Siempre buscamos clientes que compartan nuestros valores.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default Team
